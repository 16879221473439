<template>
     <el-empty
             v-bind="$attrs"
             :image="empty"
     >
          <slot></slot>
     </el-empty>
</template>

<script>
    const empty = require("./img/empty.png");
    export default {
        name: "r-e-empty",
        data() {
            return {
                empty
            };
        },
    };
</script>

<style scoped>

</style>
