import {tokenCookies} from "../storage/cookies";
import {showFullScreenLoading} from "../custom/loading";

export function requestInterceptors(config) {

    // 默认全局Content-Type = application/json;charset=utf-8
    // 默认get和options， application/x-www-form-urlencoded;charset=utf-8
    if(config.method === 'options' || config.method === 'get') {
        config.headers[ 'Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }

    // 获取token
    // config.headers['X-Access-Token'] = tokenCookies.getToken() || null
    config.headers['Authorization'] = tokenCookies.get() || null

    // 配置loading加载
    const loadingMessage = config['loadMsg'] || null
    loadingMessage && showFullScreenLoading('body', loadingMessage)

    return config;
}


