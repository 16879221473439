<template>
    <section>
        <div style="height: 31px;overflow:hidden;">
            <el-tabs v-model="editableTabsValue" type="border-card" @tab-click="handleClick"
                     :closable="editableTabs.length > 1" @tab-remove="removeTab">
                <el-tab-pane v-for="item in editableTabs" :key="item.name" :label="item.title" :name="item.name"/>
            </el-tabs>
        </div>
    </section>
</template>

<script>
export default {
    name: "tabs-bar",
    data() {
        return {
            editableTabsValue: '1',
            editableTabs: [],
        };
    },
    mounted() {
        const route = this.$route;
        const {name, meta: {title}} = route;
        this.editableTabs.push({name, title});
        this.editableTabsValue = name;
    },
    methods: {
        handleClick(tab, event) {
            const route = this.$route;
            const {name} = route;

            if (tab.name !== name) this.$router.push({name: tab.name});
        },
        addTab(data) {
            const editableTabs = this.editableTabs;
            if(data.name.includes("/")) data.name = data.name.replace('/','');

            const {name} = data;
            const editableTab = editableTabs.find(item => item.name === name);
            if (!editableTab) {
                this.editableTabs.push(data);
            }
            this.editableTabsValue = name;
        },
        removeTab(targetName) {
            let tabs = this.editableTabs;
            let activeName = this.editableTabsValue;
            if (activeName === targetName) {
                tabs.forEach((tab, index) => {
                    if (tab.name === targetName) {
                        let nextTab = tabs[index + 1] || tabs[index - 1];
                        if (nextTab) activeName = nextTab.name;
                    }
                });
            }
            this.editableTabsValue = activeName;
            this.editableTabs = tabs.filter(tab => tab.name !== targetName);
            const route = this.$route;
            const {name} = route;
            if (activeName !== name) this.$router.push({name: activeName});
        }
    }
}
</script>


<style lang="scss" scoped>
/deep/ .el-tabs__item {
    padding: 0 10px;
    height: 30px;
    box-sizing: border-box;
    line-height: 30px;
    display: inline-block;
    list-style: none;
    font-size: 12px;
    font-weight: 500;
    color: #303133;
    position: relative;
    border: 1px solid skyblue;
}

/deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    border: 1px solid skyblue;
    margin-top: 0;
    color: #909399;
}

/deep/ .el-tabs__nav-next, /deep/ .el-tabs__nav-prev {
    position: absolute;
    cursor: pointer;
    line-height: 31px;
    font-size: 12px;
    color: #909399;
}
</style>
