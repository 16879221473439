import { getCommunityListApi } from "@/api/community-management";

const state = {};

const mutations = {};

const actions = {
    async GetCommunityListActions({ commit }, payload) {
        try {
            // 获取小区+楼栋+单元列表
            return await getCommunityListApi(payload) || {};
        } catch (e) {
            console.error("获取小区列表", e);
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
