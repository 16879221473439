import request from '@http';

// 获取所有父类
export function getParents(params) {
    return request({
        method: 'GET',
        url: '/api/gzf/data/all/parent',
        params
    })
}

export function getByName(name) {
    return request({
        method: 'GET',
        url: '/api/gzf/data/name?name=' + name
    })
}

export function getByCode(code) {
    return request({
        method: 'GET',
        url: '/api/gzf/data/code?code=' + code
    })
}

// 根据根据父类uuid获取所有子类 uuid多个父类uuid，用逗号隔开
export function getByParent(uuid) {
    return request({
        method: 'GET',
        url: '/api/gzf/data/parent?uuid=' + uuid
    })
}

// 根据根据父类code获取所有子类 codes多个父类code，用逗号隔开
export function getByCodesParent(codes) {
    return request({
        method: 'GET',
        url: '/api/gzf/data/code/parent?code=' + codes
    })
}


export function addDictionaryApi(data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/data/create',
        data
    })
}

export function editDictionaryApi(uuid, data) {
    return request({
        method: 'PUT',
        url: '/api/gzf/data/update/' + uuid,
        data
    })
}
