<script>
export default {
  name: "TableColumn",
  functional: true,
  props: {
    column: {
      //列
      type: Object,
      default: () => ({}),
    },
    index: [Number, String],
  },
  render: (h, ctx) => {
    const params = {
      index: ctx.props.index,
    };
    if (ctx.props.column) params.column = ctx.props.column;
    let scopedSlots = {};
    if (ctx.props.column.render) {
      scopedSlots = {
        default: (scope) =>
          ctx.props.column.render(h, {
            ...params,
            row: scope.row,
            scope,
          }),
      };
    }
    const {
      type,
      selectable,
      label,
      prop,
      align,
      sortable,
      width,
      renderHeader,
      fixed,
      resizable,
      showOverflowTooltip,
      minWidth,
      sortMethod,
      className,
      reserveSelection,
    } = ctx.props.column;
    return h("el-table-column", {
      props: {
        type,
        selectable,
        label,
        prop,
        align: align || "left",
        sortable,
        width,
        fixed,
        resizable,
        "render-header": renderHeader,
        "class-name": className,
        "min-width": minWidth,
        "sort-method": sortMethod,
        "reserve-selection": reserveSelection,
        "show-overflow-tooltip": showOverflowTooltip,
      },
      scopedSlots,
    });
  },
};
</script>
