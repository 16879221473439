import {Message, MessageBox} from "element-ui";

export function MessageError(message, time, callback) {
    Message.error({
        message: message,
        duration: time || 2000,
        showClose: true,
        onClose: callback
    })
}

export function MessageSuccess(message, time, callback) {
    Message.success({
        message: message,
        duration: time || 2000,
        showClose: true,
        onClose: callback
    })
}

export function MessageInfo(message, time, callback) {
    Message.info({
        message: message,
        duration: time || 2000,
        showClose: true,
        onClose: callback
    })
}

export function MessageWarning(message, time, callback) {
    Message.warning({
        message: message,
        duration: time || 2000,
        showClose: true,
        onClose: callback
    })
}

export function MessageBoxConfirm(callBack, message = '此操作将进行删除, 是否继续?') {
    MessageBox.confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
        callBack()
    }).catch(() => {
        MessageInfo('已取消')
    })
}
