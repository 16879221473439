// 生成百分比的宽高
export class VwVh {
    constructor(width = 1920, height = 1080) {
        this.$width = width;
        this.$height = height;
    }

    VW(px) {
        return px / this.$width * 100 + "vw";
    }

    VH(px) {
        return px / this.$height * 100 + "vh";
    }
}
