import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import * as cookies from "@storage/cookies";

Vue.use(VueRouter);

// 不需要动态判断权限的路由
export const constantRoutes = [
    {
        path: "/login",
        component: () => import("@/views/login/index.vue"),
    },
    {
        path: "/404",
        component: () => import("@/views/exceptional/404.vue"),
    },
    {
        path: "/demo",
        component: () => import("@/components/Upload/demo.vue"),
    }
];

const createRouter = () => new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
});

const router = createRouter();

// 路由重置
export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher;
    cookies.tokenCookies.remove();
    store.dispatch("user/routeFlagActions", false);
}

export default router;
