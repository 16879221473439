<template>
    <section class="app-wrapper">
        <nav-bar class="layout-item nav-bar"/>
        <tabs-bar class="layout-item tabs-bar" ref="tabsBar"/>
        <side-bar class="layout-item side-bar" @addTab="addTab"/>
        <transition name="fade-transform" mode="out-in">
            <div class="layout-item main-container">
                <keep-alive>
                    <router-view v-if="$route.meta.keepAlive"/>
                </keep-alive>
                <router-view v-if="!$route.meta.keepAlive" :key="key"/>
            </div>
        </transition>
    </section>
</template>

<script>
import navBar from "./components/nav-bar";
import sideBar from "./components/side-bar";
import tabsBar from "./components/tabs-bar";

export default {
    name: "layout",
    components: {
        navBar, sideBar, tabsBar,
    },
    computed: {
        key() {
            // 防止缓存
            const name = this.$route.name;
            const timestamp = new Date().getTime();
            return name !== undefined ? name + +timestamp : this.$route + +timestamp;
        }
    },
    methods:{
        addTab(data){
            this.$refs['tabsBar'].addTab(data);
        }
    }
};
</script>

<style lang="scss" scoped>
$navBarWidth: VW(1705px);
$navBarHeight: VH(98px);

.app-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    .layout-item {
        position: absolute;
    }

    .side-bar {
        width: VW(215px);
        height: 100vh;
        top: 0;
        left: 0;
    }

    .nav-bar {
        width: $navBarWidth;
        height: $navBarHeight;
        top: 0;
        left: VW(215px);
    }

    .tabs-bar {
        width: $navBarWidth;
        left: VW(215px);
        top: VH(80px);
        z-index: 1000;
    }

    .main-container {
        width: $navBarWidth;
        height: VH(982px);
        overflow: hidden;
        top: $navBarHeight;
        left: VW(215px);
        background: #F3F3F3;
    }
}
</style>
