import Vue from 'vue'

Vue.directive('dialogDrag', {
    bind: function (el) {
        //弹框可拉伸最小宽高
        let minWidth = 400;
        let minHeight = 300;
        //初始非全屏
        let isFullScreen = false;
        //当前宽高
        let nowWidth = 0;
        let nowHight = 0;
        //当前顶部高度
        let nowMarginTop = 0;
        //获取弹框头部（这部分可双击全屏）
        const dialogHeaderEl = el.querySelector('.el-dialog__header');
        //弹窗
        const dragDom = el.querySelector('.el-dialog');
        //给弹窗加上overflow auto；不然缩小时框内的标签可能超出dialog；
        dragDom.style.overflow = "auto";
        //头部加上可拖动cursor
        dialogHeaderEl.style.cursor = 'move';
        // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
        const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null);
        let moveDown = (e) => {
            // 鼠标按下，计算当前元素距离可视区的距离
            const disX = e.clientX - dialogHeaderEl.offsetLeft;
            const disY = e.clientY - dialogHeaderEl.offsetTop;
            // 获取到的值带px 正则匹配替换
            let styL, styT;
            // 上次鼠标弹起时X轴的偏移量
            let l = +dragDom.style.left.replace(/px/, "");
            // 上次鼠标弹起时Y轴的偏移量
            let t = +dragDom.style.top.replace(/px/, "");
            let x = e.pageX - l;
            let y = e.pageY - t;
            let markL = 0,
                markT = 0,
                markR = 0,
                markB = 0,
                offset = 10; // 偏移量
            document.onmousemove = (e) => {
                // 处理右边界
                if (dragDom.offsetLeft + dragDom.offsetWidth + offset >= el.offsetWidth) {
                    markR === 0 && (markR = e.pageX - x);
                    e.pageX - x < markR && (dragDom.style.left = e.pageX - x + "px");
                } else if (dragDom.offsetLeft > offset) {
                    dragDom.style.left = e.pageX - x + "px";
                } else {
                    // 处理左边界
                    markL === 0 && (markL = e.pageX - x);
                    e.pageX - x > markL && (dragDom.style.left = e.pageX - x + "px");
                }
                // 处理下边界
                if (dragDom.offsetTop + dragDom.offsetHeight + offset >= el.offsetHeight) {
                    markB === 0 && (markB = e.pageY - y);
                    e.pageY - y < markB && (dragDom.style.top = e.pageY - y + "px");
                } else if (dragDom.offsetTop > offset) {
                    dragDom.style.top = e.pageY - y + "px";
                } else {
                    // 处理上边界
                    markT === 0 && (markT = e.pageY - y);
                    e.pageY - y > markT && (dragDom.style.top = e.pageY - y + "px");
                }
            }
            document.onmouseup = function (e) {
                document.onmousemove = null;
                document.onmouseup = null;
            };
        };
        dialogHeaderEl.onmousedown = moveDown;
        //双击头部全屏效果
        dialogHeaderEl.ondblclick = (e) => {
            if (!isFullScreen) {
                nowHight = dragDom.clientHeight;
                nowWidth = dragDom.clientWidth;
                nowMarginTop = dragDom.style.marginTop;
                dragDom.style.left = 0;
                dragDom.style.top = 0;
                dragDom.style.height = "100VH";
                dragDom.style.width = "100VW";
                dragDom.style.marginTop = 0;
                isFullScreen = true;
                dialogHeaderEl.style.cursor = 'initial';
                dialogHeaderEl.onmousedown = null;
            } else {
                dragDom.style.height = "auto";
                dragDom.style.width = nowWidth + 'px';
                dragDom.style.marginTop = nowMarginTop;
                dragDom.style.overflow = 'hidden';
                isFullScreen = false;
                dialogHeaderEl.style.cursor = 'move';
                dialogHeaderEl.onmousedown = moveDown;
            }
        };
        dragDom.onmousemove = function (e) {
            if (e.clientX > dragDom.offsetLeft + dragDom.clientWidth - 10 || dragDom.offsetLeft + 10 > e.clientX) {
                dragDom.style.cursor = 'w-resize';
            } else if (el.scrollTop + e.clientY > dragDom.offsetTop + dragDom.clientHeight - 10) {
                dragDom.style.cursor = 's-resize';
            } else {
                dragDom.style.cursor = 'default';
                dragDom.onmousedown = null;
            }
            dragDom.onmousedown = (e) => {
                const clientX = e.clientX;
                const clientY = e.clientY;
                let elW = dragDom.clientWidth;
                let elH = dragDom.clientHeight;
                let EloffsetLeft = dragDom.offsetLeft;
                let EloffsetTop = dragDom.offsetTop;
                dragDom.style.userSelect = 'none';
                let ELscrollTop = el.scrollTop;
                //判断点击的位置是不是为头部
                if (clientX > EloffsetLeft && clientX < EloffsetLeft + elW && clientY > EloffsetTop && clientY < EloffsetTop + 100) {
                    //如果是头部在此就不做任何动作，以上有绑定dialogHeaderEl.onmousedown = moveDown;
                    return;
                } else {
                    document.onmousemove = function (e) {
                        //左侧鼠标拖拽位置
                        if (clientX > EloffsetLeft && clientX < EloffsetLeft + 10) {
                            //往左拖拽
                            if (clientX > e.clientX) {
                                dragDom.style.width = elW + (clientX - e.clientX) * 2 + 'px';
                            }
                            //往右拖拽
                            if (clientX < e.clientX) {
                                if (dragDom.clientWidth >= minWidth) {
                                    dragDom.style.width = elW - (e.clientX - clientX) * 2 + 'px';
                                }
                            }
                        }
                        //右侧鼠标拖拽位置
                        if (clientX > EloffsetLeft + elW - 10 && clientX < EloffsetLeft + elW) {
                            //往左拖拽
                            if (clientX > e.clientX) {
                                if (dragDom.clientWidth >= minWidth) {
                                    dragDom.style.width = elW - (clientX - e.clientX) * 2 + 'px';
                                }
                            }
                            //往右拖拽
                            if (clientX < e.clientX) {
                                dragDom.style.width = elW + (e.clientX - clientX) * 2 + 'px';
                            }
                        }
                        //底部鼠标拖拽位置
                        if (ELscrollTop + clientY > EloffsetTop + elH - 20 && ELscrollTop + clientY < EloffsetTop + elH) {
                            //往上拖拽
                            if (clientY > e.clientY) {
                                if (dragDom.clientHeight >= minHeight) {
                                    dragDom.style.height = elH - (clientY - e.clientY) + 'px';
                                }
                            }
                            //往下拖拽
                            if (clientY < e.clientY) {
                                dragDom.style.height = elH + (e.clientY - clientY) + 'px';
                            }
                        }
                    };
                    //拉伸结束
                    document.onmouseup = function (e) {
                        document.onmousemove = null;
                        document.onmouseup = null;
                    };
                }
            }
        }
    }


});